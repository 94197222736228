import React from "react"

import Header from "./header/header"

const BlogHeader = props =>
  <Header isFullWidthHeader={true}
    onCTAClicked={props.onCTAClicked}
    activeSection={props.activeSection}
    items={[
      { page: "/books", title: "Book notes" },
      { page: "/blog", title: "Blog" },
      { page: "/", title: "Portfolio" }
  ]} />

export default BlogHeader
