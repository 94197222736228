import React from "react"
import { Link } from "gatsby"
import { rhythm } from "../../utils/typography"
import Img from "gatsby-image"

const RichCard = ({ url, type="get-book", image, title, subtitle, description, source }) =>
  <Link to={url} style={{width: "100%"}} >
    <figure class="blog-rich-card" data-card-type={type}>
      <div class="book-cover">      
        <Img fixed={image} />
      </div>
      <div class="book-details">
        <h3
          style={{
            marginBottom: rhythm(1 / 4),
          }}
        >
          {title}
        </h3>                
        <div class="book-author">{subtitle}</div>
        <div class="book-impression">{description}</div>
        <div class="affiliate-source" data-source={source} />
      </div>
      </figure>
    </Link>;

export default RichCard;
