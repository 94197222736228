import React from "react"
import styled from "styled-components"
import { MDXProvider } from "@mdx-js/react"

import { rhythm, scale } from "../utils/typography"

import Footer from "./footer"
import Header from "./blogHeader"

import RichCard from "./cards/richCard"

const shortcodes = { RichCard }

// TODO header bar, small bio under article title

class Layout extends React.Component {
  render() {
    const { location, title, pageType="page", children, leftAside, rightAside, below, maxWidth=rhythm(48), contentMaxWidth } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    const blogPath = `${__PATH_PREFIX__}/blog/`

    /*
    let header

    if (location.pathname === rootPath || location.pathname === blogPath) {
      header = (
        <h1
          style={{
            ...scale(1.5),
            marginBottom: rhythm(1.5),
            marginTop: 0,
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={location.pathname === blogPath ? `/blog/` : `/`}
          >
            {title}
          </Link>
        </h1>
      )
    } else {
      header = (
        <h3
          style={{
            fontFamily: `Montserrat, sans-serif`,
            marginTop: 0,
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/blog/`}
          >
            {title}
          </Link>
        </h3>
      )
    }
    */

    const noAsides = !leftAside && !rightAside;

    return (
      <Wrapper>
        <Header />
        <div class="blog-post-layout" data-page-type={pageType}>
          <div
            class="blog-post-wrapper"
            style={{
              marginLeft: `auto`,
              marginRight: `auto`,
              maxWidth,
              padding: `${rhythm(6)} ${rhythm(3 / 4)} ${rhythm(2)} ${rhythm(3 / 4)}`,
            }}
          >
            {!noAsides && <div class="left-aside">{leftAside}</div>}
            <main style={{ maxWidth: contentMaxWidth || maxWidth, margin: 'auto' }}>
              <MDXProvider components={shortcodes}>{children}</MDXProvider>
            </main>
            {!noAsides && <div class="right-aside">{rightAside}</div>}
          </div>
          <div class="below-wrapper">{below}</div>        
        </div>        
        <Footer enableAOS={false} />
      </Wrapper>
    )
  }
}

const Wrapper = styled.div`
  min-height: 100vh;
`

export default Layout
